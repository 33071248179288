<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.53988 0H5.91988C7.32988 0 8.45988 1.15 8.45988 2.561V5.97C8.45988 7.39 7.32988 8.53 5.91988 8.53H2.53988C1.13988 8.53 -0.00012207 7.39 -0.00012207 5.97V2.561C-0.00012207 1.15 1.13988 0 2.53988 0ZM2.53988 11.4697H5.91988C7.32988 11.4697 8.45988 12.6107 8.45988 14.0307V17.4397C8.45988 18.8497 7.32988 19.9997 5.91988 19.9997H2.53988C1.13988 19.9997 -0.00012207 18.8497 -0.00012207 17.4397V14.0307C-0.00012207 12.6107 1.13988 11.4697 2.53988 11.4697ZM17.46 0H14.08C12.67 0 11.54 1.15 11.54 2.561V5.97C11.54 7.39 12.67 8.53 14.08 8.53H17.46C18.86 8.53 20 7.39 20 5.97V2.561C20 1.15 18.86 0 17.46 0ZM14.08 11.4697H17.46C18.86 11.4697 20 12.6107 20 14.0307V17.4397C20 18.8497 18.86 19.9997 17.46 19.9997H14.08C12.67 19.9997 11.54 18.8497 11.54 17.4397V14.0307C11.54 12.6107 12.67 11.4697 14.08 11.4697Z"
      fill="#030229"
    />
  </svg>
</template>

<script>
export default {
  name: "AppGridIcon",
};
</script>
